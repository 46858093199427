import React from "react";
import img from "../../../img/no.png";

const SafetyMultiBar = ({type, number, timePeriods, matchedDataWater }) => {
  const formatValue = (value) => {
    if (value >= 1e6) {
      return `${(value / 1e6).toFixed(1)}M`; // Format millions
    } else if (value >= 1e3) {
      return `${(value / 1e3).toFixed(1)}K`; // Format thousands
    } else {
      return Math.round(value); // Format normal numbers
    }
  };

  const formatNumberWithIndianCommas = (number) => {
    const x = number.toString().split(".");
    let num = x[0];
    let lastThree = num.slice(-3);
    const rest = num.slice(0, -3);

    if (rest !== "") {
      lastThree = "," + lastThree;
      const result = rest.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
      num = result + lastThree;
    } else {
      num = lastThree;
    }

    return x.length > 1 ? num + "." + x[1] : num; // Just format the number without manipulating the input string
  };
  // Extract unique categories dynamically based on the specified condition
  const categories = matchedDataWater.reduce((acc, item) => {
    if (item.question_details) {
      // Check for 'column1' first

      // If no 'column1' found, check for 'column'

      let filteredOptions = item.question_details
        .filter((detail) => detail.option_type === "column")
        .map((detail) => detail.option)

        .reverse();

      return acc.concat(filteredOptions);
    }
    return acc;
  }, []);

  // Remove duplicates to get unique categories
  const uniqueCategories = [...new Set(categories)];

  // Prepare data for each time period
  const seriesData = Object.keys(timePeriods).map((timePeriod, timeIndex) => {
    const totalValues = uniqueCategories.map((category, categoryIndex) => {
      // Find the corresponding matchedDataWater object for the time period (H1, H2, etc.)
      const timePeriodData = matchedDataWater[timeIndex];
      if (timePeriodData && timePeriodData.question_details) {
        // Check for the matched detail for the category
        const matchedDetail = timePeriodData.question_details.find(
          (detail) => detail.option === category
        );
        if (
          matchedDetail &&
          timePeriodData.answer &&
          timePeriodData.answer[number]
        ) {
          // Extract the value from the corresponding index in the answer array
          const answerIndex = number === 4 ? 0 : number;
          const answerValue = timePeriodData.answer[answerIndex][categoryIndex]; // Assume answers align with category index
          return answerValue !== undefined && !isNaN(Number(answerValue))
            ? Number(answerValue)
            : 0;
        }
      }
      return 0; // Default to 0 if no data is found
    });

    return {
      name: timePeriod,
      data: totalValues,
    };
  });

  const adjustAndRoundTotalSum = (totalSum) => {
    if (totalSum < 1) {
      // Handle small decimals
      if (totalSum < 0.01) {
        // Round to nearest 0.005 for values below 0.01
        return Math.ceil(totalSum * 200) / 200; // (0.005 increments)
      } else if (totalSum < 0.1) {
        // Round to nearest 0.01 for values between 0.01 and 0.1
        return Math.ceil(totalSum * 100) / 100; // (0.01 increments)
      } else {
        // Round to nearest 0.5 or 1 for values between 0.1 and 1
        return Math.ceil(totalSum * 2) / 2; // (0.5 increments)
      }
    }

    const totalStr = totalSum.toString(); // Convert number to a string
    const firstTwoDigits = parseInt(totalStr.slice(0, 2)); // Extract the first two digits
    const magnitude = Math.pow(10, totalStr.length - 2); // Calculate the magnitude based on the number of digits

    // Apply custom rounding logic based on the first two digits
    if (firstTwoDigits > 75) {
      return 100 * magnitude; // Round to 100
    } else if (firstTwoDigits > 50) {
      return 75 * magnitude; // Round to 75
    } else if (firstTwoDigits > 25) {
      return 50 * magnitude; // Round to 50
    } else if (firstTwoDigits > 10) {
      return 25 * magnitude; // Round to 25
    } else {
      return 10 * magnitude; // Round to 10
    }
  };

  // Calculate the total sum for labels
  const totalSum = adjustAndRoundTotalSum(
    seriesData.reduce(
      (sum, series) => sum + series.data.reduce((a, b) => a + b, 0),
      0
    )
  );

  // Colors for each category
  const colors = [
    "#C6CB8D",
    "#949776",
    "#ABC4B2",
    "#6D8B96",
    "#9CDFE3",
    "#11546f",
    "#587b87",
    "#8CBBCE",
  ];

  const categoryShortNames = {
    "Lost Time Injury Frequency Rate (LTIFR) (per one million-person hours worked)":
      "LTIFR",
    "Total Recordable Work-Related Injuries": "Recordable Injuries",
    "Number of Fatalities": "Fatalities",
    "High Consequence Work-Related Injury or Ill-Health (excluding fatalities)":
      "HCWRI",
    // Add more mappings as needed
  };

  return (
    <div className="container">
      <div style={{ fontSize: "18px", fontWeight: 600, height: "10%" }}>
        {(() => {
          const a = number; // your number logic here; replace this with your actual number source
          switch (a) {
            case 0:
              return `Permanent Male ${type}`;
            case 1:
              return `Permanent Female ${type}`;
            case 2:
              return `Other than Permanent Male ${type}`;
            case 3:
              return `Other than Permanent Female ${type}`;
            case 4:
              return `Safety related Incidents`;
            default:
              return "";
          }
        })()}
      </div>

      {/* Check for totalSum */}
      {totalSum > 0 ? (
        <>
          <div
            className="renewable-bar-labels"
            style={{ marginTop: "1%", paddingLeft: "5%" }}
          >
            <span style={{ fontSize: "11px" }}>0</span>
            <span style={{ fontSize: "11px" }}>
              {formatValue(totalSum / 5)}
            </span>
            <span style={{ fontSize: "11px" }}>
              {formatValue((totalSum / 5) * 2)}
            </span>
            <span style={{ fontSize: "11px" }}>
              {formatValue((totalSum / 5) * 3)}
            </span>
            <span style={{ fontSize: "11px" }}>
              {formatValue((totalSum / 5) * 4)}
            </span>
            <span style={{ fontSize: "11px" }}>
              {formatValue((totalSum / 5) * 5)}
            </span>
          </div>
          <div className="renewable-bar-dotted-line"></div>

          {seriesData.length > 0 ? (
            <div
              style={{
                display: "flex",
                height: "90%",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {seriesData.map((series, seriesIndex) => (
                <div
                  className="d-flex"
                  style={{ width: "100%", marginBottom: "5%" }}
                >
                  <div
                    style={{
                      fontWeight: 500,
                      color: "#7b91b0",
                      marginBottom: "5px",
                      paddingTop: "2%",
                      fontSize: "12px",
                      marginRight: "2%",
                    }}
                  >
                    {series.name.charAt(0).toUpperCase() + series.name.slice(1)}
                  </div>
                  <div
                    key={seriesIndex}
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      margin: "5px 0",
                      height: "100%",
                      backgroundColor: "#f3f3f3",
                      width: "100%",
                    }}
                  >
                    {series.data.map(
                      (value, index) =>
                        value > 0 && (
                          <div
                            key={index}
                            style={{
                              height: "100%", // Adjust width as necessary
                              width: `${(value / totalSum) * 100}%`, // Calculate height based on total
                              backgroundColor: colors[index % colors.length],
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "white",
                              fontSize: "12px",
                              fontWeight: 400,
                              position: "relative",
                            }}
                            title={`${uniqueCategories[index]}: ${value}`}
                          ></div>
                        )
                    )}
                  </div>
                </div>
              ))}

              <div
                className="unit"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "2%",
                  marginBottom: "1%",
                  marginTop: "-2%",
                }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    height: "100%",
                    padding: "0%",
                  }}
                >
                  (Number of Individuals)
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  width: "100%",
                  overflow: "auto",
                }}
              >
                {uniqueCategories.map((category, index) => (
                  <div
                    key={index}
                    style={{
                      width: "33%",
                      display: "flex",
                      alignItems: "center",
                      marginRight: "15px",
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "15px",
                          height: "15px",
                          borderRadius: "50%",
                          backgroundColor: colors[index % colors.length],
                          marginRight: "5px",
                        }}
                      />
                    </div>
                    <div style={{ width: "70%" }}>
                      <div style={{ fontSize: "12px" }}>
                        {categoryShortNames[category] || category}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <p>No categories available for the selected options.</p>
          )}
        </>
      ) : (
        // Display image when totalSum is not greater than 0
        <div style={{ textAlign: "center" }}>
          <img
            src={img}
            alt="No data available"
            style={{ maxWidth: "150px", height: "150px" }}
          />
        </div>
      )}
    </div>
  );
};

export default SafetyMultiBar;
