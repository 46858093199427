import React, { useEffect } from "react";
import TopComponentEnergy from "./TopComponentEnergy";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import TotalEnergyConsumption from "./FrameworkOne/TotalEnergyConsumption";
import "./energy.css";
import ProductEnergyConsumption from "./ProductEnergyConsumption";
import LocationEnergyConsumption from "./LocationEnergyConsumption";
import TimeBasedProductMix from "./TimeBasedProductMix";
import LocationWise from "./LocationWise";
import OverTheYear from "./OverTheYear";
import ProductWiseEnergyConsumption from "./ProductWiseEnergyConsumption";
import EnergyIntensity from "./EnergyIntensity";
import TotalProductWise from "../Company Sub Admin/Pages/home/TotalProductWise";
import EnergyConsumptionBar from "../Environment/EnergyConsumptionBar";
import ProductWiseSingleLoc from "./ProductWiseSingleLoc";
import EnergyIntensitySingleLoc from "./EnergyIntensitySingleLoc";
import EnergyEfficiencyImprovement from "./EnergyEffImprove";
import Renewable from "../Environment/RenewableAndNoneRenew";
import DonutChart from "../Environment/DonutChart";
import OnSiteSingleLoc from "./OnSiteSingleLoc";
import GreenEnergyPurchaseSingle from "./GreenEnergyPurchaseSingle";
import WaterDonutChart from "../Environment/DonutChart";
import SingleLocSingleTime from "./SingleLocSingleTime";
import MultipleLocationMultipleTime from "./MultipleLocationMultipleTime";
import SingleLocMultTime from "./SingleLocMultTime";
import { filter } from "lodash";
import EnergyIntensityMulti from "./EnergyIntensity";

const Energy = ({
  locationOption,
  timePeriods,
  financialYearId,
  graphData,
  sectorData,
  frameworkValue,
  sectorQuestionAnswerDataForGraph,
  keyTab,
}) => {
  console.log("graph", graphData, sectorQuestionAnswerDataForGraph);
  const [lastWeekActivities, setLastWeekActivities] = useState({});
  const [companyFramework, setCompanyFramework] = useState([]);
  const [timePeriodValues, setTimePeriodValues] = useState([]);
  const [renewableEnergy, setRenewableEnergy] = useState();
  const [nonRenewableEnergy, setNonRenewableEnergy] = useState();
  const [totalConsumptionRenewable, setTotalConsumptionRenewable] = useState();
  const [totalConsumptionNonRenewable, setTotalConsumptionNonRenewable] =
    useState();
  const [locations, setLocations] = useState([""]);
  const [quarters, setQuarters] = useState([""]);
  const [brief, setBrief] = useState();
  // console.log("graphData", graphData);

  const icons = {
    0: done,
    1: updated,
    2: due,
    3: defaulted,
  };

  function convertMixedData(mixedArray) {
    return mixedArray.map((data) => {
      if (Array.isArray(data.answer) && Array.isArray(data.answer[0])) {
        const flattenedAnswer = data.answer.flat();
        const summedValue = flattenedAnswer.reduce(
          (sum, value) => sum + (parseFloat(value) || 0),
          0
        );

        return {
          questionId: data.questionId,
          sourceId: data.sourceId,
          answer: {
            process: 1,
            readingValue: summedValue.toString(),
            unit: "KG",
          },
          title: data.title,
          question_details: data.question_details,
          formDate: data.formDate,
          toDate: data.toDate,
        };
      } else {
        return {
          ...data,
          answer: {
            ...data.answer,
            readingValue: data.answer.readingValue || "0",
          },
        };
      }
    });
  }

  useEffect(() => {
    // Safeguard against undefined graphData or timePeriods
    if (companyFramework && companyFramework.includes(1)) {
      const timePeriodsArray = Object.values(timePeriods || []);
      setTimePeriodValues(timePeriodsArray);

      const energyRenew =
        graphData?.filter(
          (item) => item.title === "Energy Consumption from Renewable Sources"
        ) || [];
      const nonEnergyRenew =
        graphData?.filter(
          (item) =>
            item.title === "Energy Consumption from Non-renewable Sources"
        ) || [];

      // Safeguard against undefined timePeriodsArray
      const newEnergyRenew = energyRenew.filter((item) =>
        timePeriodsArray.includes(item.formDate)
      );
      const newNonEnergyRenew = nonEnergyRenew.filter((item) =>
        timePeriodsArray.includes(item.formDate)
      );

      const finalEnergy = newEnergyRenew.filter((item) =>
        locationOption.some((location) => location.id === item.sourceId)
      );

      const finalNonEnergy = newNonEnergyRenew.filter((item) =>
        locationOption.some((location) => location.id === item.sourceId)
      );

      setRenewableEnergy(finalEnergy);
      setNonRenewableEnergy(finalNonEnergy);

      if (!Array.isArray(finalEnergy) || finalEnergy.length === 0) {
        setTotalConsumptionRenewable(0);
        return;
      }

      const firstObject = finalEnergy[0];

      // Extract all the rows but skip the first one
      const rowOptions = firstObject.question_details
        .filter((detail) => detail.option_type === "row") // Filter by "row" option_type
        .slice(1) // Skip the first row
        .map((detail) => detail.option)
        .reverse(); // Extract the "option" values

      const aggregatedValues = rowOptions.map((_, index) =>
        finalEnergy.reduce((acc, obj) => {
          const value =
            obj.answer && Array.isArray(obj.answer) && obj.answer[index]?.[0];
          return (
            acc +
            (value === "NA" || !value || value === "No" || value === "Yes"
              ? 0
              : parseFloat(value || 0))
          );
        }, 0)
      );

      console.log(aggregatedValues);

      const total = aggregatedValues.reduce((sum, value) => sum + value, 0);
      setTotalConsumptionRenewable(total);

      const firstObjectNon = finalNonEnergy[0];

      // Extract all the rows but skip the first one
      const rowOptionsNon = firstObjectNon.question_details
        .filter((detail) => detail.option_type === "row") // Filter by "row" option_type
        .slice(1) // Skip the first row
        .map((detail) => detail.option)
        .reverse(); // Extract the "option" values

      const aggregatedValuesNon = rowOptionsNon.map((_, index) =>
        finalNonEnergy.reduce((acc, obj) => {
          const value =
            obj.answer && Array.isArray(obj.answer) && obj.answer[index]?.[0];
          return (
            acc +
            (value === "NA" || !value || value === "No" || value === "Yes"
              ? 0
              : parseFloat(value || 0))
          );
        }, 0)
      );

      const totalNon = aggregatedValuesNon.reduce(
        (sum, value) => sum + value,
        0
      );
      setTotalConsumptionNonRenewable(totalNon);
    }
     else if (companyFramework && companyFramework.includes(48)) {
      const timePeriodsArray = Object.values(timePeriods || []);
      setTimePeriodValues(timePeriodsArray);
      const valuesArray = locationOption
        ? locationOption.map((item) => item.unitCode || item.value)
        : [];

      const transformedKeys = Object.keys(timePeriods).map((key) =>
        key.toUpperCase()
      );

      // setSelection(view === "time" ? valuesArray[0] : transformedKeys[0]);
      setQuarters(transformedKeys);
      setLocations(valuesArray);

      const summary = {
        time: {},
        location: {},
      };

      if (locationOption) {
        locationOption.forEach((location) => {
          transformedKeys.forEach((quarter) => {
            summary.location[quarter] = {
              "Diesel consumption": new Array(locationOption.length).fill(0),
              "LPG consumption": new Array(locationOption.length).fill(0),
              "Petrol consumption": new Array(locationOption.length).fill(0),
              "CNG consumption": new Array(locationOption.length).fill(0),
              "PNG consumption": new Array(locationOption.length).fill(0),
              "Electricity Power plant (Captive Power Plant - Natural Gas)":
                new Array(locationOption.length).fill(0),
              "Electricity consumption through DG": new Array(
                locationOption.length
              ).fill(0),
              "Total electricity consumption from Renewable energy (via PPA)":
                new Array(locationOption.length).fill(0),
              "Total electricity consumption from Renewable energy (rooftop solar)":
                new Array(locationOption.length).fill(0),
              "Total electricity consumption (GRID electricity)": new Array(
                locationOption.length
              ).fill(0),
            };
          });
        });
      }

      if (transformedKeys) {
        transformedKeys.forEach((quarter) => {
          locationOption.forEach((location) => {
            summary.time[location.unitCode] = {
              "Diesel consumption": new Array(transformedKeys.length).fill(0),
              "LPG consumption": new Array(transformedKeys.length).fill(0),
              "Petrol consumption": new Array(transformedKeys.length).fill(0),
              "CNG consumption": new Array(transformedKeys.length).fill(0),
              "PNG consumption": new Array(transformedKeys.length).fill(0),
              "Electricity Power plant (Captive Power Plant - Natural Gas)":
                new Array(transformedKeys.length).fill(0),
              "Electricity consumption through DG": new Array(
                transformedKeys.length
              ).fill(0),
              "Total electricity consumption from Renewable energy (via PPA)":
                new Array(transformedKeys.length).fill(0),
              "Total electricity consumption from Renewable energy (rooftop solar)":
                new Array(transformedKeys.length).fill(0),
              "Total electricity consumption (GRID electricity)": new Array(
                transformedKeys.length
              ).fill(0),
            };
          });
        });
      }

      if (graphData) {
        const filteredData = graphData.filter(
          (item) =>
            item.questionId === 289 ||
            item.questionId === 292 ||
            item.questionId === 293 ||
            item.questionId === 294 ||
            item.questionId === 295 ||
            item.questionId === 426 ||
            item.questionId === 429 ||
            item.questionId === 430 ||
            item.questionId === 468
        );
        const convertedData = convertMixedData(filteredData);
        const timeKey = [];
        const locationKey = [];

        for (const period in summary.location) {
          timeKey.push(period);
        }

        for (const period in summary.time) {
          locationKey.push(period);
        }

        for (const location in summary.time) {
          const data = summary.time[location];
          for (const key in data) {
            for (let k = 0; k < summary.time[location][key].length; k++) {
              let time = timeKey[k];
              const obj = locationOption.find(
                (item) => item.unitCode === location
              );
              const lowerCaseKey = time.toLowerCase();
              const formDate = timePeriods[lowerCaseKey];
              const filterData = convertedData.find(
                (item) =>
                  item.title === key &&
                  item.formDate === formDate &&
                  item.sourceId === obj.id
              );
              summary.time[location][key][k] =
                Number(filterData?.answer?.readingValue) || 0;
            }
          }
        }
        for (const time in summary.location) {
          const data = summary.location[time];
          for (const key in data) {
            for (let k = 0; k < summary.location[time][key].length; k++) {
              let location = locationKey[k];
              const obj = locationOption.find(
                (item) => item.unitCode === location
              );
              const lowerCaseKey = time.toLowerCase();
              const formDate = timePeriods[lowerCaseKey];
              const filterData = convertedData.find(
                (item) =>
                  item.title === key &&
                  item.formDate === formDate &&
                  item.sourceId === obj.id
              );
              summary.location[time][key][k] =
                Number(filterData?.answer?.readingValue) || 0;
            }
          }
        }
      }

      setBrief(summary);
      console.log(summary, locations, quarters, "sdfgdsertd");
    }
  }, [locationOption, graphData, timePeriods, companyFramework]);

  console.log(locationOption, timePeriods);

  useEffect(() => {
    const activityData = {
      "Total Energy": {
        number: `${
          Number(totalConsumptionRenewable) +
          Number(totalConsumptionNonRenewable)
        } GJ`,
        questionId: [],
      },
      "Renewable Energy": {
        number: `${Number(totalConsumptionRenewable || 0)} GJ`,
        questionId: [],
      },
      "Non Renewable Energy": {
        number: `${Number(totalConsumptionNonRenewable || 0)} GJ`,
        questionId: [],
      },
      message: "Good Evening, Sunil Kumar",
    };
    setLastWeekActivities(activityData);
  }, [totalConsumptionRenewable, totalConsumptionNonRenewable]);

  useEffect(() => {
    if (frameworkValue?.length) {
      const frameworkId = frameworkValue.map((value) => value.id);
      setCompanyFramework(frameworkId);
    }
  }, [frameworkValue]);

  return (
    <div className="progress-container">
      {companyFramework &&
        companyFramework.length > 0 &&
        companyFramework.includes(1) && (
          <div className="topcompo">
            {lastWeekActivities && (
              <TopComponentEnergy
                lastWeekActivities={lastWeekActivities}
                icons={icons}
              />
            )}
          </div>
        )}

      {companyFramework &&
      companyFramework.length &&
      companyFramework.includes(1) ? (
        (timePeriodValues &&
          locationOption.length === 1 &&
          timePeriodValues.length === 1) ||
        (locationOption.length > 1 &&
          timePeriodValues.length === 1 &&
          keyTab === "combined") ? (
          <>
            <SingleLocSingleTime
              graphData={graphData}
              keyTab={keyTab}
              locationOption={locationOption}
              brief={brief}
              timePeriods={timePeriods}
              timePeriodValues={timePeriodValues}
              renewableEnergy={renewableEnergy}
              nonRenewableEnergy={nonRenewableEnergy}
              totalConsumptionRenewable={totalConsumptionRenewable}
              totalConsumptionNonRenewable={totalConsumptionNonRenewable}
              companyFramework={companyFramework}
            />
          </>
        ) : (locationOption.length > 1 &&
            timePeriodValues.length > 1 &&
            keyTab === "combined") ||
          (locationOption.length > 1 && timePeriodValues.length === 1) ||
          (locationOption.length == 1 && timePeriodValues.length > 1) ? (
          <>
            <SingleLocMultTime
              graphData={graphData}
              keyTab={keyTab}
              locationOption={locationOption}
              timePeriods={timePeriods}
              timePeriodValues={timePeriodValues}
              renewableEnergy={renewableEnergy}
              nonRenewableEnergy={nonRenewableEnergy}
              totalConsumptionRenewable={totalConsumptionRenewable}
              totalConsumptionNonRenewable={totalConsumptionNonRenewable}
              companyFramework={companyFramework}
            />
          </>
        ) : (
          <>
            <MultipleLocationMultipleTime />
          </>
        )
      ) : (timePeriodValues &&
          locationOption.length === 1 &&
          timePeriodValues.length === 1) ||
        (locationOption.length > 1 &&
          timePeriodValues.length === 1 &&
          keyTab === "combined") ? (
        <>
          <SingleLocSingleTime
            graphData={graphData}
            keyTab={keyTab}
            companyFramework={companyFramework}
            brief={brief}
          />
        </>
      ) : (locationOption.length > 1 &&
          timePeriodValues.length > 1 &&
          keyTab === "combined") ||
        (locationOption.length > 1 && timePeriodValues.length === 1) ||
        (locationOption.length == 1 && timePeriodValues.length > 1) ? (
        <>
          <SingleLocMultTime
            graphData={graphData}
            keyTab={keyTab}
            brief={brief}
            locationOption={locationOption}
            timePeriods={timePeriods}
            timePeriodValues={timePeriodValues}
            renewableEnergy={renewableEnergy}
            nonRenewableEnergy={nonRenewableEnergy}
            totalConsumptionRenewable={totalConsumptionRenewable}
            totalConsumptionNonRenewable={totalConsumptionNonRenewable}
            companyFramework={companyFramework}
          />
        </>
      ) : (
        timePeriodValues && (
          <>
            <div className="d-flex flex-column flex-space-between">
              <div
                className="d-flex flex-row flex-space-between"
                style={{
                  gap: "20px",
                  height: "75vh",
                  marginBottom: "3%",
                  width: "76vw",
                }}
              >
                <div className="nothing" style={{ width: "50%" }}>
                  <EnergyIntensityMulti
                    locationOption={locationOption}
                    timePeriods={timePeriods}
                    brief={brief}
                  />
                </div>
                <div className="nothing" style={{ width: "50%" }}>
                  <ProductWiseEnergyConsumption
                    locationOption={locationOption}
                    timePeriods={timePeriods}
                    financialYearId={financialYearId}
                    graphData={graphData}
                  />
                </div>
              </div>
              {/* <div
                className="d-flex flex-row flex-space-between"
                style={{ height: "70vh", marginBottom: "3%", width: "76vw" }}
              ></div> */}
            </div>
          </>
        )
      )}
    </div>
  );
};

export default Energy;
