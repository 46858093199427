import React from "react";

import TotalWasteGeneratedByTpe from "./FrameworkOne/TotalWasteGeneratedByTpe";
import WasteRecyPie from "./FrameworkOne/WasteRecyPie";
import WasteGeneratedFourtyEight from "./FrameworkFourtyEight/WasteGeneratedFourtyEight";
import WasteDonutFourtyEight from "./FrameworkFourtyEight/WasteDonutFourtyEight";

const SingleLocSingleTime = ({
  companyFramework,
  sectorQuestionAnswerDataForGraph,
  timePeriods,
  brief,
  graphData,
  matchedDataWaste,
  wasteDisposal,
  wasteRecovered,
  locationOption,

  keyTab,
  timePeriodValues,
 
}) => {
  return companyFramework.includes(1) ? 
  (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "60vh", marginBottom: "3%" }}
      >
        <div className="firsthalfprogressenergy" style={{display:"flex", flexDirection:"column", justifyContent:"space-between", width: "50%" }}>
            <div style={{height:"45%"}}>
                <TotalWasteGeneratedByTpe title={"Total Waste Generated"} timePeriods={timePeriods} timePeriodValues={timePeriodValues} locationOption={locationOption} matchedDataWaste={matchedDataWaste}/>

            </div>

            <div style={{height:"45%"}}>
                <TotalWasteGeneratedByTpe title={"Total Waste Disposed"}  timePeriods={timePeriods} timePeriodValues={timePeriodValues} locationOption={locationOption} matchedDataWaste={wasteDisposal}/>

                
                </div>
       
         
        </div>
        <div className="secondhalfprogress" style={{display:"flex", flexDirection:"column", justifyContent:"space-between",width:"50%"}}>
            <WasteRecyPie timePeriods={timePeriods} timePeriodValues={timePeriodValues} locationOption={locationOption} matchedDataWaste={wasteRecovered}/>

          
        </div>
      </div>
      {/* <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "22vh", marginBottom: "3%" }}
      >
      
      </div> */}
    </div>
  ) : (
    <>
      <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "60vh", marginBottom: "3%" }}
      >
        <div className="firsthalfprogressenergy" style={{display:"flex", flexDirection:"column", justifyContent:"space-between", width: "50%" }}>
            <div style={{height:"55%"}}>
              <WasteGeneratedFourtyEight timePeriods={timePeriods} timePeriodValues={timePeriodValues} locationOption={locationOption} brief={brief}/>

            </div>

            <div style={{height:"0%"}}>
                {/* <EnergyConsumptionChart totalConsumptionRenewable={totalConsumptionRenewable} totalConsumptionNonRenewable={totalConsumptionNonRenewable} /> */}

                
                </div>
       
         
        </div>
        <div className="secondhalfprogress" style={{display:"flex", flexDirection:"column", justifyContent:"space-between",width:"55%"}}>
          <div style={{ height: "100%" }}>
            <WasteDonutFourtyEight brief={brief}/>
       
          </div>
          <div style={{ height: "0%" }}>
            {/* <CommonBarComponent
            renewableEnergy={renewableEnergy}
            nonRenewableEnergy={nonRenewableEnergy}
            com={"non"}
/> */}
        
          </div>
        </div>
      </div>
      {/* <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "22vh", marginBottom: "3%" }}
      >
    
      </div> */}
    </div>


    </>
  );
};

export default SingleLocSingleTime;
