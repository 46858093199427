// import React from "react";
// import Chart from "react-apexcharts";

// const EnergyConsumptionChart = ({
//   totalConsumptionRenewable,
//   totalConsumptionNonRenewable,
// }) => {
//   const maxConsumption = Math.max(totalConsumptionRenewable, totalConsumptionNonRenewable);
//   const yAxisMax = maxConsumption + 10000;
  
//   const formatNumberWithIndianCommas = (number) => {
//     const x = number.toString().split('.');
//     let num = x[0];
//     let lastThree = num.slice(-3);
//     const rest = num.slice(0, -3);
    
//     if (rest !== '') {
//       lastThree = ',' + lastThree;
//       const result = rest.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
//       num = result + lastThree;
//     } else {
//       num = lastThree;
//     }
    
//     return x.length > 1 ? num + '.' + x[1] : num; // Just format the number without manipulating the input string
//   };// Add 10,000 to the higher value

//   const calculateTickAmountAndStep = (yAxisMax) => {
//     if (yAxisMax === 0) return { tickAmount: 1, step: 1 }; // Handle edge case for 0
  
//     const magnitude = Math.pow(10, Math.floor(Math.log10(yAxisMax))); // Get the magnitude of the max value
  
//     // Determine the step size and tickAmount based on magnitude
//     let step;
//     if (yAxisMax <= 10) {
//       step = 1;  // Steps of 1 if the max is <= 10
//     } else if (yAxisMax <= 100) {
//       step = 10; // Steps of 10 if the max is <= 100
//     } else if (yAxisMax <= 1000) {
//       step = 100; // Steps of 100 if the max is <= 1000
//     } else {
//       step = magnitude; // For larger numbers, use the magnitude as step size
//     }
  
//     const tickAmount = Math.ceil(yAxisMax / step); // Calculate the number of ticks
  
//     return { tickAmount, step };
//   };
  
//   // Example usage of the function to configure your chart's y-axis
//   const yAxisConfig = (yAxisMax) => {
//     const { tickAmount, step } = calculateTickAmountAndStep(yAxisMax);
  
//     return {
//       labels: {
//         formatter: function (value) {
//           return Math.round(value); // Round off to the nearest whole number
//         },
//         style: {
//           colors: '#7b91b0',  // Light blue color for the y-axis labels
//           fontSize: '12px',   // Adjust the label font size if needed
//           fontFamily: 'Poppins',
//         },
//       },
//       min: 0,
//       max: yAxisMax,  // Set the dynamic max value
//       tickAmount: tickAmount,  // Set the dynamic tick amount
//     };
//   };
  
//   // Chart options
//   const chartOptions = {
//     chart: {
//       type: "bar",
//       height: 350,
//     },
//     plotOptions: {
//       bar: {
//         horizontal: false,
//         columnWidth: "30%",
//         borderRadius: 5,
//         distributed: true,
//         endingShape: "rounded",
//         dataLabels: {
//           position: "center", // place labels inside the bars
//         },
//       },
//     },
//     dataLabels: {
//       enabled: true,
//       formatter: function (val) {
//         return formatNumberWithIndianCommas(val); // Display values inside bars
//       },
//       offsetY: 0, // Center the label vertically inside the bar
//       style: {
//         fontSize: "10px",
//         colors: ["#fff"], // White text inside bars
//       },
//     },
//     stroke: {
//       show: true,
//       width: 2,
//       colors: ["transparent"],
//     },
//     xaxis: {
//       categories: ["Renewable Energy", "Non-Renewable Energy"], // X-Axis Labels
//       labels: {
//         style: {
//           colors: '#7b91b0',  // Light blue color for the y-axis labels
//           fontSize: '12px',   // Adjust the label font size if needed
//           fontFamily: 'Poppins',
//         },
//       },
//     },
//     yaxis: yAxisConfig(yAxisMax),
//     fill: {
//       opacity: 1,
//     },
//     tooltip: {
//       y: {
//         formatter: function (val) {
//           return val ;
//         },
//       },
//     },
//     colors: ["#004b68", "#a1d6e2"], // Matches the bar colors in the image
//     grid: {
//       strokeDashArray: 3, // Dotted gridlines
//     },
//     legend: {
//       show: true,
//       markers: {
//         width: 0, // Hides default legend markers (squares)
//         height: 0,
//       },
//       position: "bottom", // Adjust as necessary (top, right, bottom, left)
//       horizontalAlign: "center", // Align legend items in the center
//       itemMargin: {
//         horizontal: 10, // Space between legend items
//         vertical: 0, // Vertical space (if needed)
//       },
//       formatter: function (seriesName, opts) {
//         return `<div style="display: flex; align-items: center;">
//                   <div style="background-color: ${
//                     opts.w.config.colors[opts.seriesIndex]
//                   }; width: 12px; height: 12px; border-radius: 50%; margin-right: 5px;"></div>
//                   <span style="color: #7b91b0;">${seriesName}</span>
//                 </div>`;
//       },
//     },
//   };

//   // Chart data
//   const chartSeries = [
//     {
//       name: "Energy Consumption",
//       data: [totalConsumptionRenewable, totalConsumptionNonRenewable], // Renewable and Non-Renewable values
//     },
//   ];

//   return (
//     <div className="container" style={{ height: "100%" }}>
//       <div style={{ fontSize: "18px", fontWeight: 600, height: "10%" }}>
//         Renewable & Non-Renewable Energy Consumption 
//       </div>
//       <div style={{ height: "90%" }}>
//         <Chart
//           options={chartOptions}
//           series={chartSeries}
//           type="bar"
//           height={"100%"}
//         />
//       </div>
//     </div>
//   );
// };

// export default EnergyConsumptionChart;


import React from "react";
import Chart from "react-apexcharts";

const EnergyConsumptionChart = ({
  totalConsumptionRenewable,
  totalConsumptionNonRenewable,
}) => {
  const maxConsumption = Math.max(totalConsumptionRenewable, totalConsumptionNonRenewable);
  const yAxisMax = maxConsumption + 10000; // Add 10,000 to the higher value

  const formatNumberWithIndianCommas = (number) => {
    const x = number.toString().split('.');
    let num = x[0];
    let lastThree = num.slice(-3);
    const rest = num.slice(0, -3);
    
    if (rest !== '') {
      lastThree = ',' + lastThree;
      const result = rest.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
      num = result + lastThree;
    } else {
      num = lastThree;
    }
    
    return x.length > 1 ? num + '.' + x[1] : num; // Just format the number without manipulating the input string
  };

  const getRoundedMaxAndStep = (yAxisMax) => {
    let roundedMax;
    let step;

    if (yAxisMax === 0) return { roundedMax: 1, step: 1 }; // Handle edge case for 0

    const magnitude = Math.pow(10, Math.floor(Math.log10(yAxisMax)));

    // Determine the step size and tickAmount based on magnitude
    if (yAxisMax <= 10) {
      roundedMax = Math.ceil(yAxisMax);
      step = 1;  // Steps of 1 if the max is <= 10
    } else if (yAxisMax <= 100) {
      roundedMax = Math.ceil(yAxisMax / 10) * 10; // Round up to nearest 10
      step = 10; // Steps of 10 if the max is <= 100
    } else if (yAxisMax <= 1000) {
      roundedMax = Math.ceil(yAxisMax / 100) * 100; // Round up to nearest 100
      step = 100; // Steps of 100 if the max is <= 1000
    } else {
      roundedMax = Math.ceil(yAxisMax / magnitude) * magnitude; // For larger numbers, use the magnitude as step size
      step = magnitude; // Use the magnitude as step size
    }

    return { roundedMax, step };
  };

  const yAxisConfig = (yAxisMax) => {
    const { roundedMax, step } = getRoundedMaxAndStep(yAxisMax);
  
    const tickAmount = Math.ceil(roundedMax / step); // Calculate the number of ticks
  
    return {
      labels: {
        formatter: function (value) {
          if (value >= 1e6) {
            return `${(value / 1e6).toFixed(0)} M`; // Format millions
          } else if (value >= 1e3) {
            return `${(value / 1e3).toFixed(0)} K`; // Format thousands
          } else {
            return Math.round(value); // Format normal numbers
          }
        },
        style: {
          colors: '#7b91b0',  // Light blue color for the y-axis labels
          fontSize: '12px',   // Adjust the label font size if needed
          fontFamily: 'Poppins',
        },
      },
      min: 0,
      max: roundedMax,  // Set the dynamic max value
      tickAmount: tickAmount,  // Set the dynamic tick amount
      title: {
        text: "Energy in GJ", // Title text
        style: {
          fontSize: '12px',    // Title font size
          fontWeight: 400,     // Title font weight
          fontFamily: 'Poppins', // Title font family
          color: '#7b91b0',     // Title color
        },
      },
      
    };
  };
  

  // Chart options
  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false, // Hides toolbar if needed
      },
      marginBottom: '20px',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        borderRadius: 5,
        distributed: true,
        endingShape: "rounded",
        dataLabels: {
          position: "center", // place labels inside the bars
        },
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return formatNumberWithIndianCommas(val); // Display values inside bars
      },
      offsetY: 0, // Center the label vertically inside the bar
      style: {
        fontSize: "10px",
        colors: ["#fff"], // White text inside bars
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Renewable Energy", "Non-Renewable Energy"], // X-Axis Labels
      labels: {
        style: {
          colors: '#7b91b0',  // Light blue color for the y-axis labels
          fontSize: '12px',   // Adjust the label font size if needed
          fontFamily: 'Poppins',
        },
      },
    },
    yaxis: yAxisConfig(yAxisMax),
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return formatNumberWithIndianCommas(val); // Format tooltip values
        },
      },
    },
    colors: ["#004b68", "#a1d6e2"], // Matches the bar colors in the image
    grid: {
      strokeDashArray: 3, // Dotted gridlines
    },
    legend: {
      show: true,
      markers: {
        width: 0, // Hides default legend markers (squares)
        height: 0,
      },
      position: "bottom", // Adjust as necessary (top, right, bottom, left)
      horizontalAlign: "center", // Align legend items in the center
      itemMargin: {
        horizontal: 10, // Space between legend items
        vertical: 10, // Vertical space (if needed)
      },
      formatter: function (seriesName, opts) {
        return `<div style="display: flex; align-items: center;">
                  <div style="background-color: ${
                    opts.w.config.colors[opts.seriesIndex]
                  }; width: 12px; height: 12px; border-radius: 50%; margin-right: 5px;"></div>
                  <span style="color: #7b91b0;">${seriesName}</span>
                </div>`;
      },
    },
  };

  // Chart data
  const chartSeries = [
    {
      name: "Energy Consumption",
      data: [totalConsumptionRenewable, totalConsumptionNonRenewable], // Renewable and Non-Renewable values
    },
  ];

  return (
    <div className="container" style={{ height: "100%" }}>
      <div style={{ fontSize: "18px", fontWeight: 600, height: "10%" }}>
        Renewable & Non-Renewable Energy Consumption 
      </div>
      <div style={{ height: "90%" }}>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={"100%"}
        />
      </div>
    </div>
  );
};

export default EnergyConsumptionChart;
