import React from "react";
import Chart from "react-apexcharts";

export const StackedSafetyNonPerm = ({title, data }) => {
  // Extract categories (X-axis) from question_details
  const categories = data[0].question_details
    .filter((detail) => detail.option_type === "column")
    .map((detail) => detail.option)
    .reverse();

  // Extract values for Permanent Male and Permanent Female Workers
  const permanentMaleWorkers = data[0].answer[2].map((val) => Number(val));
  const permanentFemaleWorkers = data[0].answer[3].map((val) => Number(val));

  // Chart options
  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false, // Remove toolbar
      },
    },
    xaxis: {
      categories, // Categories for X-axis
    },
    plotOptions: {
      bar: {
        horizontal: false, // Vertical bars
        columnWidth: "20%", // Reduce the bar width
        dataLabels: {
          position: 'center' // Set the data label position to center
        }
      },
    },
    yaxis: {
      title: {
        text: "Number of Individuals", // Y-axis title
      },
    },
    legend: {
      position: "bottom", // Legend at the bottom
      horizontalAlign: "center", // Center the legend
      markers: {
        shape: "circle",
        height: 12,
        width: 12,
        radius: 12, // Legend markers as circles
      },
    },
    dataLabels: {
      enabled: false, // Enable data labels on the bars
      offsetY: -10, // Adjust the position (optional)
      style: {
        colors: ["#fff"], // Color of the labels (white for better contrast)
      },
      formatter: function (val) {
        return val; // Display the value
      },
    },
    colors: ["#11546f", "#DB8080"], // Custom colors for the bars (Male and Female)
    title: {
      text: "",
      align: "center",
    },
  };

  // Series data for Permanent Male and Female Workers
  const series = [
    {
      name: "Male",
      data: permanentMaleWorkers,
    },
    {
      name: " Female ",
      data: permanentFemaleWorkers,
    },
  ];

  return (
    <div className="container" style={{ width: "100%" }}>
      <div
        style={{
          height: "10%",
          fontSize: "20px",
          fontWeight: 600,
          fontColor: "#011627",
        }}
      >
        {title}
      </div>

      <div style={{ height: "90%" }}>
        <Chart
          options={options}
          series={series}
          type="bar"
          height={"100%"}
          width={"100%"}
        />
      </div>
    </div>
  );
};
