import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import deleteIcon from "../img/deleteIcon.png";
import editIcon from "../img/editIcon.png";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import TrainingModal from "./TrainingModal";
import { Modal, Button } from "react-bootstrap";
import NoDataFound from "../../../img/no_data_found.png";
import Toast from "react-bootstrap/Toast";
import AddTrainee from "./AddTrainee";
import copyIcon from "../img/CopyIcon.png";
import AddIcon from "../img/AddTraineeIcon.png";

const TrainingTable = () => {
  const [showModal, setShowModal] = useState(false);
  const [editTrainingId, setEditTrainingId] = useState(null);
  const [editData, setEditData] = useState(null);
  const [trainings, setTrainings] = useState([]);
  const [actionId, setActionId] = useState(null);
  const [actionModalShow, setActionModalShow] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showAddTraineeModal, setShowAddTraineeModal] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [financialYear, setFinancialYear] = useState([]);
  const [financialYearId, setFinancialYearId] = useState(0);

  const handleActionClose = () => {
    setActionId(null);
    setActionModalShow(false);
    setShowAddTraineeModal(false);
  };

  const getTrainingData = async (fId) => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTrainingData`,
      {},
      {financialYearId : fId||financialYearId},
      "GET"
    );

    if (isSuccess) {
      const tmpData =
        data?.data && data?.data.length ? data?.data.reverse() : [];
      const formattedData = tmpData.map((item) => ({
        item,
        id: item.id,
        date: new Date(item.date).toLocaleDateString(),
        time: `${item.fromTime} to ${item.toTime}`,
        trainer: item.trainers,
        title: item.trainingTitle,
        trainingLink: item.trainingLink,
        mode: item.modeOfTraining,
        targetAudience: JSON.parse(item.targetAudience),
      }));

      setTrainings(formattedData);
    }
  };

  const handleActionAPI = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}deleteTrainingData`,
      {},
      { trainingId: actionId },
      "POST"
    );

    if (isSuccess) {
      handleActionClose();
      getTrainingData();
    }
  };

  const handleSaveTrainee = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}addTraineeOrInviteTrainee`,
      {},
      {
        trainingId: actionId,
        traineeEmails: selectedEmails.map((item) => item.email),
      },
      "POST"
    );

    if (isSuccess) {
      handleActionClose();
    }
  };

  const handleDelete = (id) => {
    setActionId(id);
    setActionModalShow(true);
  };

  const handleCloseModal = () => {
    getTrainingData();
    setShowModal(false);
  };

  const handleEdit = (id, training) => {
    setShowModal(true);
    setActionId(id);
    setEditData(training.item);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCopyLink = (link) => {
    if (link) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          setShowToast(true);
        })
        .catch((err) => {
          console.error("Failed to copy link: ", err);
        });
    } else {
      alert("No link available to copy.");
    }
  };
  const handleSave = (id) => {
    setTrainings(
      trainings.map((training) => (training.id === id ? editData : training))
    );
    setEditTrainingId(null);
  };

  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );

    if (isSuccess) {
      setFinancialYear(data.data);
      if (data?.data?.length) {
        setFinancialYearId(data.data[data?.data?.length - 1].id);
        getTrainingData(data.data[data?.data?.length - 1].id);
      }
    }
  };

  useEffect(() => {
    getFinancialYear();
  }, []);

  useEffect(() => {
    getTrainingData();
  }, [financialYearId]);

  const renderTable = () => {
    return (
      <div
        style={{
          ...tableContainerStyle,
          padding: "20px",
          border: "1px solid white",
          backgroundColor: "white",
        }}
      >
        <div>
          <div style={searchContainerStyle}>
            <input
              type="text"
              className="form-control"
              placeholder="Search topic name"
              style={{ borderRadius: "12px", marginBottom: "1em" }}
            />
          </div>
        </div>
        {trainings && trainings.length ? (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th
                  style={{ ...tableHeaderStyle, width: "5%", fontSize: "16px" }}
                >
                  {" "}
                  Id
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    width: "7% ",
                    fontSize: "16px",
                  }}
                >
                  Date
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    width: "12%",
                    fontSize: "16px",
                  }}
                >
                  Training Time
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    width: "12%",
                    fontSize: "16px",
                  }}
                >
                  Trainer Name
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    width: "13%",
                    fontSize: "16px",
                  }}
                >
                  Training Title
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    width: "10%",
                    fontSize: "16px",
                  }}
                >
                  Mode
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    width: "17%",
                    fontSize: "16px",
                  }}
                >
                  Audience
                </th>
                <th
                  style={{ ...tableHeaderStyle, fontSize: "16px" }}
                  className="text-left"
                >
                  Operation
                </th>
              </tr>
            </thead>
            <tbody>
              {trainings.map((item) => (
                <tr
                  key={item.id}
                  style={{
                    backgroundColor: item.id % 2 === 0 ? "white" : "white",
                    borderBottom: "1px solid #83BBD5",
                  }}
                >
                  <td style={tableDataStyle}>
                    {String(item.id).padStart(2, "0")}
                  </td>
                  {editTrainingId === item.id ? (
                    <>
                      <td style={tableDataStyle}>
                        <input
                          type="text"
                          name="date"
                          value={editData.date}
                          onChange={handleChange}
                          style={editInputStyle}
                        />
                      </td>
                      <td style={tableDataStyle}>
                        <input
                          type="text"
                          name="time"
                          value={editData.time}
                          onChange={handleChange}
                          style={editInputStyle}
                        />
                      </td>
                      <td style={tableDataStyle}>
                        <input
                          type="text"
                          name="trainer"
                          value={editData.trainer}
                          onChange={handleChange}
                          style={editInputStyle}
                        />
                      </td>
                      <td style={tableDataStyle}>
                        <input
                          type="text"
                          name="title"
                          value={editData.title}
                          onChange={handleChange}
                          style={editInputStyle}
                        />
                      </td>
                      <td style={tableDataStyle}>
                        <input
                          type="text"
                          name="mode"
                          value={editData.mode}
                          onChange={handleChange}
                          style={editInputStyle}
                        />
                      </td>
                      <td style={tableDataStyle}>
                        <input
                          type="text"
                          name="targetAudience"
                          value={editData.targetAudience}
                          onChange={handleChange}
                          style={editInputStyle}
                        />
                      </td>
                      <td style={tableDataStyle}>
                        <button
                          style={generateLinkStyle}
                          onClick={() => handleSave(item.id)}
                        >
                          Save
                        </button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td style={tableDataStyle}>{item.date}</td>
                      <td style={tableDataStyle}>{item.time}</td>
                      <td style={tableDataStyle}>{item.trainer}</td>
                      <td style={tableDataStyle}>{item.title}</td>
                      <td style={tableDataStyle}>{item.mode}</td>
                      <td style={tableDataStyle}>
                        {item.targetAudience
                          .map((audience) => {
                            switch (audience) {
                              case "EMPLOYEES_PERMANENT":
                                return "Permanent Employees";
                              case "EMPLOYEES_TEMPORARY":
                                return "Other than Permanent Employees";
                              case "WORKERS_PERMANENT":
                                return "Permanent Workers";
                              case "WORKERS_TEMPORARY":
                                return "Other than Permanent Workers";
                              case "KMP":
                                return "KMP";
                              case "BOD":
                                return "BOD";
                              default:
                                return audience;
                            }
                          })
                          .join(", ")}
                      </td>
                      <td style={tableDataStyle} className="text-left">
                        <button
                          className=""
                          style={generateLinkStyle}
                          onClick={() => handleCopyLink(item?.trainingLink)}
                        >
                          <img
                            src={copyIcon}
                            alt="CopyLink"
                            title="Copy Link"
                          />
                        </button>
                        <button
                          style={generateLinkStyle}
                          onClick={() => {
                            setShowAddTraineeModal(true);
                            setActionId(item?.id);
                          }}
                        >
                          <img src={AddIcon} alt="Add" title="Add Trainee" />
                          {/* Add Trainee */}
                        </button>
                        <span
                          onClick={() => handleEdit(item.id, item)}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={editIcon}
                            alt="edit"
                            className="ms-2 me-2"
                            title="Edit"
                          />
                        </span>
                        <span
                          onClick={() => handleDelete(item.id)}
                          style={{ cursor: "pointer" }}
                        >
                          <img src={deleteIcon} alt="delete" title="Delete" />
                        </span>
                        {/* <span
                          onClick={() => handleDelete(item.id)}
                          style={{ cursor: "pointer" }}
                        >
                          <img src={deleteIcon} alt="delete" title="Delete" />
                        </span> */}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="hstack justify-content-center">
            <img src={NoDataFound} alt="" srcset="" />
          </div>
        )}
        <Modal
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            backdropFilter: "blur(10px)",
          }}
          show={showAddTraineeModal}
          onHide={handleActionClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Guest</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddTrainee
              selectedEmails={selectedEmails}
              setSelectedEmails={setSelectedEmails}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              style={{ width: "100%" }}
              onClick={handleSaveTrainee}
            >
              Send Invite
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="TrainingTableModal"
          style={TrainingTableModal}
          show={actionModalShow}
          onHide={handleActionClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleActionAPI}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          style={{
            position: "fixed",
            bottom: "90%",
            left: "50%",
            zIndex: 1000,
            transform: "translateX(-50%)",
            backgroundColor: "#28a745",
            color: "#fff",
          }}
        >
          <Toast.Body>Link copied to clipboard!</Toast.Body>
        </Toast>
      </div>
    );
  };

  return (
    <>
      <div style={headerContainerStyle}>
        <div style={headerTitleStyle}>Trainer Portal</div>
        <div>
          <select
            className="sector-question-select"
            value={
              financialYearId ||
              (financialYear.length > 0
                ? financialYear[financialYear.length - 1].id
                : "")
            }
            onChange={async (e) => {
              setFinancialYearId(e.target.value);
            }}
          >
            <option value={0}>Select Financial Year</option>
            {financialYear?.map((item, key) => (
              <option key={key} value={item.id}>
                {item.financial_year_value}
              </option>
            ))}
          </select>
        </div>
        <div>
          <button style={createButtonStyle} onClick={() => setShowModal(true)}>
            Create new training
          </button>
        </div>
      </div>
      <div style={trainingContainerStyle}>
        {trainings && trainings.length ? (
          <div style={searchContainerStyle}>
            {/* <input
              type="text"
              className="form-control"
              placeholder="Search topic name"
              style={{ borderRadius: "12px" }}
            /> */}
          </div>
        ) : (
          <></>
        )}
        <div style={tableWrapperStyle}>{renderTable()}</div>
      </div>
      <TrainingModal
        show={showModal}
        handleClose={() => handleCloseModal()}
        actionId={actionId}
        editData={editData}
        financialYearId={financialYearId}
      />
    </>
  );
};

// Inline CSS styles
const headerContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  fontSize: "15px",
  marginTop: "2em",
  marginBottom: "0.5em",
};
const headerTitleStyle = {
  fontSize: "28px",
  fontWeight: "bold",
};
const createButtonStyle = {
  backgroundColor: "#3F88A5",
  color: "white",
  borderRadius: "22px",
  border: "none",
  padding: "10px 20px",
  cursor: "pointer",
};
const TrainingTableModal = {
  // height:'max-content ',
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  backdropFilter: "blur(1px)",
  // top:'25%',
};
const trainingContainerStyle = {
  // border: "1px solid #DCDCDC",
  // borderRadius: "10px",
  // padding: "20px",
};
const tableContainerStyle = {
  marginTop: "1em",
  borderRadius: "10px",
  overflow: "hidden",
};
const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
};
const tableHeaderStyle = {
  backgroundColor: "white",
  color: "#3F88A5",
  borderBottom: "2px solid #3F88A5",
  padding: "10px ",
  textAlign: "left",
};
const tableDataStyle = {
  padding: "10px",
  fontSize: "16px",
};
const editInputStyle = {
  border: "none",
  borderBottom: "1px solid #DCDCDC",
  outline: "none",
  width: "100%",
};
const generateLinkStyle = {
  backgroundColor: "transparent",
  // color: "white",
  border: "none",
  // padding: "5px 10px",
  // borderRadius: "5px",
  cursor: "pointer",
};
const searchContainerStyle = {
  display: "flex",
  justifyContent: "flex-end",
};
const tableWrapperStyle = {
  // Add this style
  overflowX: "auto",
};

export default TrainingTable;
