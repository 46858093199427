import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentSafety from "./TopComponentSafety";
import WellBeingMeasures from "./WellBeingMeasures";
import SafetyRelatedIncidents from "./SafetyRelatedIncidents";
import SeverityRate from "./SevrityRate";
import SafetyTrainingCompletionRate from "../Training/SafetyTrainingCompletionRate";
import MeasuresForTheWellBeing from "./MeasuresForTheWellBeing";
import OccupationalHealth from "./OccupationalHealth";
import SafetyTrainingCompletionRateWorkers from "./SafetyTrainingCompletionRatesWorker";
import SafetyStandards from "./SafetyStandards";
import SafetyTrainingCompletionSingle from "./SafetyTrainingCompletionSingle";
import MeasureWellBeingWorker from "./MeasureWellBeingWorker";
import SafetySingleLocSingleTime from "./SafetySingleLocSingleTime";
import SafetySingleLocMultTime from "./SafetySingleLocMultTime";
import SafetyMultiLocMultiTime from "./FrameworkFourtyEight/SafetyMultiLocMultiTime";

const Safety = ({
  locationOption,
  timePeriods,
  financialYearId,
  keyTab,

  graphData,
  frameworkValue,
}) => {
  const location = 2;
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const icons = {
    done: done,
    updated: updated,
    due: due,
    pending: defaulted,
  };
  const [companyFramework, setCompanyFramework] = useState([]);

  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess && data?.data) {
      setLastWeekAcitivities(data.data);
    }
  };

  const [wellBeingEmployee, setWellBeingEmployee] = useState([]);
  const [wellBeingWorker, setWellBeingWorker] = useState([]);
  const [safetyMeasure, setSafetyMeasure] = useState([]);
  const [timePeriodValues, setTimePeriodValues] = useState();
  const [activebtnTab, setactivebtnTab] = useState(0);

  const [brief, setBrief] = useState();

  const handleTabClick = (index) => {
    setactivebtnTab(index);
  };

  useEffect(() => {
    // Update wellBeingEmployee and wellBeingWorker states based on filtered graphData and timePeriods.

    if (companyFramework && companyFramework.includes(1)) 
      {
      const timePeriodsArray = Object.values(timePeriods || []);
      setTimePeriodValues(timePeriodsArray);

      const empDiffIncl =
        graphData?.filter(
          (item) =>
            item.title === "Details of measures for the well-being of employees"
        ) || [];
      const workDiffIncl =
        graphData?.filter(
          (item) =>
            item.title === "Details of measures for the well-being of workers"
        ) || [];
      const safety =
        graphData?.filter(
          (item) => item.title === "Details of Safety-Related Incidents"
        ) || [];

      const matchedDiffIncl =
        empDiffIncl?.filter((item) => {
          return Object.values(timePeriods || {}).includes(item?.formDate);
        }) || [];

      const matchedWorkDiffIncl =
        workDiffIncl?.filter((item) => {
          return Object.values(timePeriods || {}).includes(item?.formDate);
        }) || [];

      const matchedSafety =
        safety?.filter((item) => {
          return Object.values(timePeriods || {}).includes(item?.formDate);
        }) || [];

      const finalEnergy = matchedDiffIncl.filter((item) =>
        locationOption.some((location) => location.id === item.sourceId)
      );

      const finalEnergyTwo = matchedWorkDiffIncl.filter((item) =>
        locationOption.some((location) => location.id === item.sourceId)
      );

      const finalEnergyThree = matchedSafety.filter((item) =>
        locationOption.some((location) => location.id === item.sourceId)
      );

      setWellBeingEmployee(finalEnergy);
      setWellBeingWorker(finalEnergyTwo);
      setSafetyMeasure(finalEnergyThree);
    } else if (companyFramework && companyFramework.includes(48)) {
      const timePeriodsArray = Object.values(timePeriods || []);
      setTimePeriodValues(timePeriodsArray);
      function convertMixedData(mixedArray) {
        return mixedArray.map((data) => {
          if (Array.isArray(data.answer) && Array.isArray(data.answer[0])) {
            const flattenedAnswer = data.answer.flat();
            const summedValue = flattenedAnswer.reduce(
              (sum, value) => sum + (parseFloat(value) || 0),
              0
            );

            return {
              questionId: data.questionId,
              sourceId: data.sourceId,
              answer: {
                process: 1,
                readingValue: summedValue.toString(),
                unit: "KG",
              },
              title: data.title,
              question_details: data.question_details,
              formDate: data.formDate,
              toDate: data.toDate,
            };
          } else {
            return {
              ...data,
              answer: {
                ...data.answer,
                readingValue: data.answer.readingValue || "0",
              },
            };
          }
        });
      }
    
      const valuesArray = locationOption
        ? locationOption.map((item) => item.unitCode || item.value)
        : [];

      const transformedKeys = Object.keys(timePeriods).map((key) =>
        key.toUpperCase()
      );

      // setSelection(view === "time" ? valuesArray[0] : transformedKeys[0]);

      const summary = {
        time: {},
        location: {},
      };

      if (locationOption) {
        locationOption.forEach((location) => {
          transformedKeys.forEach((quarter) => {
            summary.location[quarter] = {
              "Number of Fatalities": new Array(locationOption.length).fill(0),
              "High consequence injuries": new Array(
                locationOption.length
              ).fill(0),
              "Recordable injuries": new Array(locationOption.length).fill(0),

              "Recordable work related ill-health cases": new Array(
                locationOption.length
              ).fill(0),
            };
          });
        });
      }

      if (transformedKeys) {
        transformedKeys.forEach((quarter) => {
          locationOption.forEach((location) => {
            summary.time[location.unitCode] = {
              "Number of Fatalities": new Array(transformedKeys.length).fill(0),
              "High consequence injuries": new Array(
                transformedKeys.length
              ).fill(0),
              "Recordable injuries": new Array(transformedKeys.length).fill(0),
              "Recordable work related ill-health cases": new Array(
                transformedKeys.length
              ).fill(0),
            };
          });
        });
      }

      if (graphData) {
        const filteredData = graphData.filter(
          (item) =>
            item.questionId === 443 ||
            item.questionId === 444 ||
            item.questionId === 445 ||
            item.questionId === 446
        );
        const convertedData = convertMixedData(filteredData);
        const timeKey = [];
        const locationKey = [];

        for (const period in summary.location) {
          timeKey.push(period);
        }

        for (const period in summary.time) {
          locationKey.push(period);
        }

        for (const location in summary.time) {
          const data = summary.time[location];
          for (const key in data) {
            for (let k = 0; k < summary.time[location][key].length; k++) {
              let time = timeKey[k];
              const obj = locationOption.find(
                (item) => item.unitCode === location
              );
              const lowerCaseKey = time.toLowerCase();
              const formDate = timePeriods[lowerCaseKey];
              const filterData = convertedData.find(
                (item) =>
                  item.title === key &&
                  item.formDate === formDate &&
                  item.sourceId === obj.id
              );
              summary.time[location][key][k] =
                Number(filterData?.answer?.readingValue) || 0;
            }
          }
        }
        for (const time in summary.location) {
          const data = summary.location[time];
          for (const key in data) {
            for (let k = 0; k < summary.location[time][key].length; k++) {
              let location = locationKey[k];
              const obj = locationOption.find(
                (item) => item.unitCode === location
              );
              const lowerCaseKey = time.toLowerCase();
              const formDate = timePeriods[lowerCaseKey];
              const filterData = convertedData.find(
                (item) =>
                  item.title === key &&
                  item.formDate === formDate &&
                  item.sourceId === obj.id
              );
              summary.location[time][key][k] =
                Number(filterData?.answer?.readingValue) || 0;
            }
          }
        }
      }

      setBrief(summary);
    }
  }, [graphData, timePeriods, companyFramework, locationOption]);

  useEffect(() => {
    lastWeekActivity();
  }, []);

  useEffect(() => {
    if (frameworkValue?.length) {
      const frameworkId = frameworkValue.map((value) => value.id);
      setCompanyFramework(frameworkId);
    }
  }, [frameworkValue]);

  const renderUI = () => {
    if (!companyFramework || !companyFramework.length) return null;
    if (!timePeriodValues || !timePeriodValues.length) return null;

    // Early return if companyFramework is invalid

    const isSingleLocationSingleTime =
      (locationOption.length === 1 && timePeriodValues.length === 1) ||
      (locationOption.length > 1 &&
        timePeriodValues.length === 1 &&
        keyTab === "combined");

    const isMultipleLocationsOrTimes =
      (locationOption.length > 1 &&
        timePeriodValues.length > 1 &&
        keyTab === "combined") ||
      (locationOption.length > 1 && timePeriodValues.length === 1) ||
      (locationOption.length === 1 && timePeriodValues.length > 1);

    switch (activebtnTab) {
      case 1:
        return wellBeingWorker.length > 0 || Object.keys(brief).length > 0? 
        (
          companyFramework.includes(1) ? 
          (
            isSingleLocationSingleTime ? (
              <>
                <SafetySingleLocSingleTime
                  type={"worker"}
                  titleThree={"Details of Safety-Related Incidents"}
                  dataThree={safetyMeasure}
                  dataTwo={wellBeingWorker}
                  titleTwo={"Measure Of Well-Being  For Non-Permanent Worker"}
                  dataOne={wellBeingWorker}
                  titleOne={"Measure Of Well-Being  For Permanent Workers"}
                  companyFramework={companyFramework}
                />
              </>
            ) : isMultipleLocationsOrTimes ? (
              <>
                <SafetySingleLocMultTime
                  dataOne={wellBeingWorker}
                  timePeriods={timePeriods}
                  dataTwo={wellBeingWorker}
                  timePeriodValues={timePeriodValues}
                  type={"worker"}
                  dataThree={safetyMeasure}
                  companyFramework={companyFramework}
                />
              </>
            ) : (
              <>{/* Add your fallback content here */}</>
            )
          ) : isSingleLocationSingleTime ? (
            <>
              <SafetySingleLocSingleTime
                type={"worker"}
                titleThree={"Details of Safety-Related Incidents"}
                dataThree={safetyMeasure}
                timePeriodValues={timePeriodValues}
                brief={brief}
                dataTwo={wellBeingWorker}
                titleTwo={"Measure Of Well-Being  For Non-Permanent Worker"}
                dataOne={wellBeingWorker}
                titleOne={"Measure Of Well-Being  For Permanent Workers"}
                companyFramework={companyFramework}
              />
            </>
          ) : isMultipleLocationsOrTimes ? (
            <>
              <SafetySingleLocMultTime
                dataOne={wellBeingWorker}
                timePeriods={timePeriods}
                dataTwo={wellBeingWorker}
                timePeriodValues={timePeriodValues}
                brief={brief}
                type={"worker"}
                dataThree={safetyMeasure}
                companyFramework={companyFramework}
              />
            </>
          ) : (
            timePeriodValues && (
              <div className="d-flex flex-column flex-space-between">
                <div
                  className="d-flex flex-row flex-space-between"
                  style={{
                    gap: "20px",
                    height: "75vh",
                    marginBottom: "3%",
                    width: "76vw",
                  }}
                >
                  <div className="nothing" style={{ width: "50%" }}>
                    <SafetyMultiLocMultiTime brief={brief} />
                  </div>
                  <div className="nothing" style={{ width: "50%" }}></div>
                </div>
              </div>
            )
          )
        ) :  
       <>
       </>
        
      case 0:
        return wellBeingEmployee.length > 0 || Object.keys(brief).length > 0 ? 
        (
         companyFramework.includes(1) ? 
        (
          isSingleLocationSingleTime ? (
            <>
              <SafetySingleLocSingleTime
                type={"employee"}
                titleThree={"Details of Safety-Related Incidents"}
                dataThree={safetyMeasure}
                dataTwo={wellBeingEmployee}
                titleTwo={"Measure Of Well-Being  For Non-Permanent Employees"}
                dataOne={wellBeingEmployee}
                titleOne={"Measure Of Well-Being  For Permanent Employees"}
                companyFramework={companyFramework}
                brief={brief}
              />
            </>
          ) : isMultipleLocationsOrTimes ? (
            <>
              <SafetySingleLocMultTime
                dataOne={wellBeingEmployee}
                timePeriods={timePeriods}
                dataTwo={wellBeingEmployee}
                type={"employee"}
                dataThree={safetyMeasure}
                companyFramework={companyFramework}
                brief={brief}
              />
            </>
          ) : (
            <>{/* Add your fallback content here */}</>
          )
        ) : 
        isSingleLocationSingleTime ? (
          <>
            <SafetySingleLocSingleTime
              type={"employee"}
              titleThree={"Details of Safety-Related Incidents"}
              dataThree={safetyMeasure}
              dataTwo={wellBeingWorker}
              titleTwo={"Measure Of Well-Being  For Non-Permanent Employees"}
              dataOne={wellBeingWorker}
              titleOne={"Measure Of Well-Being  For Permanent Employees"}
              companyFramework={companyFramework}
              brief={brief}
            />
          </>
        ) : isMultipleLocationsOrTimes ? (
          <>
            {" "}
            <SafetySingleLocMultTime
              dataOne={wellBeingEmployee}
              timePeriods={timePeriods}
              timePeriodValues={timePeriodValues}
              dataTwo={wellBeingEmployee}
              type={"employee"}
              dataThree={safetyMeasure}
              companyFramework={companyFramework}
              brief={brief}
            />
          </>
        ) : (
          timePeriodValues && (
            <div className="d-flex flex-column flex-space-between">
              <div
                className="d-flex flex-row flex-space-between"
                style={{
                  gap: "20px",
                  height: "75vh",
                  marginBottom: "3%",
                  width: "76vw",
                }}
              >
                <div className="nothing" style={{ width: "50%" }}>
                  <SafetyMultiLocMultiTime brief={brief}/>
                </div>
                <div className="nothing" style={{ width: "50%" }}></div>
              </div>
            </div>
          )
        )

        ):<></>
        
      default:
        return null;
    }
  };

  return (
    <div className="progress-container" style={{ width: "100%" }}>
     

     {!companyFramework.includes(48) && (
    <div
      className="d-flex justify-content-between buttoncont"
      style={{ marginBottom: "25px", width: "35%", height: "7vh" }}
    >
      <button
        className={`btn button ${activebtnTab === 0 ? " activebtn" : ""}`}
        onClick={() => handleTabClick(0)}
        style={{
          paddingLeft: "5vw",
          paddingRight: "5vw",
          width: "45%",
          height: "100%",
        }}
      >
        Employee
      </button>
      <button
        className={`btn button ${activebtnTab === 1 ? " activebtn" : ""}`}
        onClick={() => handleTabClick(1)}
        style={{
          paddingLeft: "5vw",
          paddingRight: "5vw",
          width: "45%",
          height: "100%",
        }}
      >
        Worker
      </button>
    </div>
  )}
      {renderUI()}
    </div>
  );
};

export default Safety;
