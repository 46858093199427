import React from "react";
import DiversityBarComponent from "./FrameworkOne/DiversityBarComponent";
import DiversityMultipleBarComponent from "./FrameworkOne/DiversityMultipleBarComponet";
import DiversityBarComponentTwo from "./FrameworkOne/DiversityBarComponentTwo";
import DiversityMenAndWomen from "./FrameworkFourtyEight/DiversityMenAndWomen";
import AgeMenAndWomen from "./FrameworkFourtyEight/AgeMenAndWomen";

const DiversitySingleTimeSingleLoc = ({
  companyFramework,
  sectorQuestionAnswerDataForGraph,
  timePeriods,
  dataOne,
  titleOne,
  dataTwo,
  titleTwo,
  diversity,
  titleThree,
  titleFour,titleFive,
  brief,
  graphData,
  totalConsumptionRenewable,
  totalConsumptionNonRenewable,
  locationOption,
  renewableEnergy,
  nonRenewableEnergy,
  keyTab,
  timePeriodValues,
}) => {
  console.log("data", dataOne);
  return companyFramework.includes(1) ? 
  (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "50vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "45%",
          }}
        >
           <div style={{ height: "45%" }}>
           
             <DiversityBarComponent
              title={titleOne}
              dataOne={dataOne}
              timePeriodValues={timePeriodValues}
              timePeriods={timePeriods}
              locationOption={locationOption}
            />
          </div>

          <div style={{ height: "45%" }}>
            <DiversityBarComponentTwo
              title={titleFive}
              dataOne={dataTwo}
              timePeriodValues={timePeriodValues}
              timePeriods={timePeriods}
              locationOption={locationOption}
            />
          </div>
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "55%",
          }}
        >
          <div style={{ height: "45%" }}>
          <DiversityBarComponentTwo
              title={titleFour}
              dataOne={dataOne}
              timePeriodValues={timePeriodValues}
              timePeriods={timePeriods}
              locationOption={locationOption}
            />
           
          </div>

          <div style={{ height: "45%" }}>
            <DiversityBarComponent
              title={titleTwo}
              dataOne={dataTwo}
              timePeriodValues={timePeriodValues}
              timePeriods={timePeriods}
              locationOption={locationOption}
            />
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "50vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "45%",
          }}
        >
          <DiversityMultipleBarComponent data={diversity} />
          
       
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "55%",
          }}
        >
         
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="d-flex flex-column flex-space-between">
        <div
          className="d-flex flex-row flex-space-between"
          style={{ height: "40vh", marginBottom: "3%" }}
        >
          <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "45%",
            }}
          >
            <div style={{ height: "65%" }}>
            <DiversityMenAndWomen timePeriods={timePeriods} timePeriodValues={timePeriodValues} locationOption={locationOption} brief={brief}/>


            </div>

            <div style={{ height: "0%" }}>

            </div>
          </div>
          <div
            className="secondhalfprogress"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "55%",
            }}
          >
            <div style={{ height: "65%" }}>
              <AgeMenAndWomen timePeriods={timePeriods} timePeriodValues={timePeriodValues} locationOption={locationOption} brief={brief}/>

            </div>
            <div style={{ height: "0%" }}>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiversitySingleTimeSingleLoc;
