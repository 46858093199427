import React from 'react';
import Chart from 'react-apexcharts';

const DiversityMultipleBarComponent = ({ data }) => {
  // Extract the rows for x-axis categories (e.g., "Number of board members", "Number of Key Management Personnel")
  const categories = data[0].question_details
  .filter((detail) => detail.option_type === 'row')
  .map((detail) => {
    if (detail.option === "Number of Key Management Personnel") {
      return "Key Management"; // Replace the specific category name
    } else if (detail.option === "Number of board members") {
      return "Board"; // Replace the specific category name
    }
    return detail.option; // Return other options unchanged
  });



  // Extract the columns (e.g., "Female", "Male") for the stack series
  const stackCategories = data[0].question_details
    .filter((detail) => detail.option_type === 'column')
    .map((detail) => detail.option).reverse();

  // Reverse the answer array structure to match the rows and columns
  const seriesData = stackCategories.map((column, colIndex) => {
    return {
      name: column, // Name of the stack (e.g., "Female", "Male")
      data: data[0].answer.map((answer) => (answer[colIndex] !== '' ? Number(answer[colIndex]) : 0)) // Data for each row
    };
  });

  // Chart options
  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      height: 350,
      toolbar: {
        show: false, // Hide the toolbar
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '35%', // Reduced bar height
      },
    },
    xaxis: {
      categories: categories, // X-axis will have the rows
      position: 'top', // Move the x-axis to the top
    },
    yaxis: {
      title: {
        text: '',
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${val}`,
      },
    },
    dataLabels:{
      enabled:false,
      show:false,
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'bottom',
      markers: {
        shape: 'circle', // Making the legend markers circular
        size: 10,
        height: 12,
        width: 12,
        radius: 12, // Adjust marker size if needed
      },
    },
    colors: ['#88D29E', '#b0b0b0', '#11546f'], // Custom colors for the bars
  };
  
  

  return (
    <div className='container' style={{}}>
      <h4>{data[0].title}</h4>
      <Chart options={options} series={seriesData} type="bar" height={"90%"} />
    </div>
  );
};

export default DiversityMultipleBarComponent;
