import React from "react";
import { useEffect, useState } from "react";

import { apiCall } from "../../_services/apiCall";
import config from "../../config/config";
import { Modal, Dropdown, Button } from "react-bootstrap";
const QuestionFrequency = () => {
  const [show, setShow] = useState(false);
  const [id, setId] = useState({});
  const [frequency, setFrequency] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSave = async () => {
    const financialYearId = await getFinancialYear();

    const { isSuccess, data, error } = await apiCall(
      config.POSTLOGIN_API_URL_COMPANY + `saveFrequencyModule`,
      {},
      {
        financialYearId,
        moduleId: id,
        frequency: frequency,
      },
      "POST"
    );
    if (isSuccess) {
      handleClose();
      getReportingQuestions();
    }
  };

  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );

    if (isSuccess) {
     return data.data[data?.data?.length - 1].id
    }
  };

  const editItem = (id) => {
    setId(id);
    handleShow();
  };
  const handleSelect = (selectedKey) => {
    setFrequency(selectedKey);
  };

  const [data, setData] = useState([]);

  const fetchFrameworkApi = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFramework`,
      {},
      { type: "ALL" }
    );
    if (isSuccess) { 
      return data?.data.map((item) => item.id)  ;
    }
  };
  const getReportingQuestions = async () => {
    const financialYearId = await getFinancialYear();
    try {
      const response = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getFrequencyModule`,
        {},
        { frameworkIds: await fetchFrameworkApi(),financialYearId},
        "GET"
      );
      if (response.isSuccess) {
        const data = response.data;
        setData(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getReportingQuestions();
  }, []);

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Title</th>
          <th>Frequency</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {/* Map over the data array and render a table row for each item */}
        {data?.data?.map((item) => (
          <tr key={item?.moduleId}>
            <td>{item?.moduleName}</td>
            <td style={{ textTransform: "capitalize" }}>
              {typeof item?.frequencies === "string"
                ? item.frequencies.replace(/_/g, " ").toLowerCase()
                : item?.frequencies}
            </td>
            <td>
              <i
                className="fa fa-edit"
                onClick={() => editItem(item?.moduleId)}
              ></i>
            </td>
          </tr>
        ))}
      </tbody>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Frequency</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle
              id="dropdown-basic"
              style={{ backgroundColor: "transparent", color: "black" }}
            >
              {frequency || "Frequency"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="MONTHLY">Monthly</Dropdown.Item>
              <Dropdown.Item eventKey="QUARTERLY">Quarterly</Dropdown.Item>
              <Dropdown.Item eventKey="HALF_YEARLY">Half Yearly</Dropdown.Item>
              <Dropdown.Item eventKey="YEARLY">Yearly</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </table>
  );
};

export default QuestionFrequency;
