import React from "react";
import "./twobutton.css";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import image from "../../../img/Filter.png";
import { useState, useEffect } from "react";
import AssignQuestionModal from "./AssignQuestionModal";
import { useLocation } from "react-router-dom";
import ReassignQuestionModal from "./ReassignQuestionModal";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";

const TwoButtonComponent = ({
  onFilteredData,
  handleAssignedDetails,
  questionIds,
  financeObjct,
  menu,
  managementListValue,
  moduleName,
  moduleData,
  setSelectedFinancialYearId,
  setSelectedFinancialYearValue,
}) => {
  const data = "bottom";
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(moduleData);
  const location = useLocation();
  const [financialYear, setFinancialYear] = useState([]);
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterData(value);
  };

  useEffect(() => {
    // Reset searchTerm when the URL changes
    setSearchTerm("");
  }, [location]);

  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );
    if (isSuccess) {
      setSelectedFinancialYearValue(
        data.data[data.data.length - 1].financial_year_value
      );
      setSelectedFinancialYearId(data.data[data.data.length - 1].id);
      setFinancialYear(data.data.reverse());
    }
  };

  useEffect(() => {
    getFinancialYear();
  }, []);
  // Filter moduleData based on search term
  const filterData = (term) => {
    if (!term) {
      setFilteredData(moduleData);
      onFilteredData(moduleData); // Passing the entire data if no search term
      return;
    }

    const filtered = moduleData.filter((item) =>
      item.title.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredData(filtered);
    onFilteredData(filtered); // Passing the filtered data
  };

  useEffect(() => {
    if (menu !== "audit") {
      filterData(searchTerm);
    }
  }, [moduleData]);

  const handleAssignClick = () => setShowAssignModal(true);
  const handleReassignClick = () => setShowReassignModal(true);
  const handleAssignClose = () => setShowAssignModal(false);
  const handleReassignClose = () => setShowReassignModal(false);
  return (
    <div
      className="d-flex justify-content-start w-100 bg-white"
      style={{
        paddingLeft: "3%",
        height: "14vh",
        padding: "2%",
        borderRadius: "10px",
      }}
    >
      <div className="conta">
        <div className="w-100" style={{ paddingTop: "2%" }}>
          <Button
            variant="primary"
            className=" mr-2 cbn"
            style={{
              backgroundColor: "#3F88A5",
              marginBottom: "10%",
              color: "white",
              paddingRight: "10%",
              paddingLeft: "10%",
            }}
            onClick={handleAssignClick}
          >
            Assign
          </Button>
        </div>
      </div>
      <div>
        <Form.Label className="m-0 p-0">Financial Year</Form.Label>
        <Form.Select
          aria-label="Default select example"
          onChange={(e) => {
            const selectedOption = financialYear.find(
              (yeardata) => yeardata.id === parseInt(e.target.value)
            );
            if (selectedOption) {
              setSelectedFinancialYearId(selectedOption.id);
              setSelectedFinancialYearValue(
                selectedOption.financial_year_value
              );
            }
          }}
        >
          {financialYear.map((yeardata) => (
            <option key={yeardata.id} value={yeardata.id}>
              {yeardata.financial_year_value}
            </option>
          ))}
        </Form.Select>
      </div>

      <div className="search-bar-wrapper">
        <InputGroup className="search-bar h-100">
          <InputGroup.Text id="basic-addon1">
            <FaSearch />
          </InputGroup.Text>
          <FormControl
            placeholder="Search Questions"
            aria-label="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            aria-describedby="basic-addon1"
          />
        </InputGroup>
      </div>
      <div
        className="filter-icon"
        style={{
          border: "2px solid #3F88A5",
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingTop: "3px",
          borderRadius: "10px",
          width: "5%",
        }}
      >
        <img
          src={image}
          alt="filter"
          style={{ width: "20px", height: "20px" }}
        />
      </div>

      <AssignQuestionModal
        handleAssignedDetails={handleAssignedDetails}
        questionIds={questionIds}
        financeObjct={financeObjct}
        managementListValue={managementListValue}
        data={data}
        moduleName={moduleName}
        moduleData={moduleData}
        showAssignModal={showAssignModal}
        handleAssignClose={handleAssignClose}
      />
      <ReassignQuestionModal
        handleAssignedDetails={handleAssignedDetails}
        moduleData={moduleData}
        questionIds={questionIds}
        financeObjct={financeObjct}
        managementListValue={managementListValue}
        moduleName={moduleName}
        showReassignModal={showReassignModal}
        handleReassignClose={handleReassignClose}
      />
    </div>
  );
};

export default TwoButtonComponent;
