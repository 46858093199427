import React from "react";
import "./progress.css";
import { useState, useEffect } from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../../src/config/config.json";
import TopComponent from "../DashboardComponents/TopComponent";
import FrameworkComponent from "../Company Sub Admin/Pages/home/FrameworkComponent";
import AnsweredQuestions from "../AnswerComponent/answeredquestions";
import UnAnsweredQuestions from "../AnswerComponent/unansweredquestions";
import SectorProgress from "../ProgressBySector/sectorprogress";
import RecentActivity from "../RecentActivity/recentactivity";
import WorkloadProgress from "../WorkloadProgress/workloadprogress";
import SummeryGraph from "../Company Sub Admin/Pages/home/SummeryGraph";
import UnassignedPie from "../UnassignedPieChart/UnassignedPie";
import FocusAreaComponent from "../../component/Company Sub Admin/Pages/home/FocusAreaComponent";
import TeamWorkLoad from "../TeamWorkLoad/TeamWorkLoad";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import RadialBarChartWithRadioButtons from "./MultipleRadial";
import QuestionStatusBarChart from "./MyAssignedStatus";
 
const Progress = ({
  fromDate,
  toDate,
  framework,
  timePeriods,
  financialYearId,
  locationOption,
}) => {
  console.log(fromDate,toDate,framework,"dsfdwasxsdcsdc",locationOption,timePeriods,financialYearId)
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const [permissionList, setPermissionList] = useState([]);
  const [teamWorkloadDatas, setTeamWorkloadDatas] = useState(false);

 
  const [showAudit, setShowAudit] = useState(false);
  const [showReportingModule, setShowReportingModule] = useState(false);
 
  const [teamWorkloadData, setTeamWorkloadData] = useState(true);
  const [teamWorkLoadDataTwo,setTeamWorkLoadDataTwo]=useState(true)
 
  const userA = JSON.parse(localStorage.getItem('currentUser'));
 
 
  const handleTeamWorkloadData = (data,user) => {
    if (user === "audit") {
      if (data.length === 1) {
        if (data[0].firstName !== userA.first_name) {
          setTeamWorkloadData(true); // Set teamWorkLoad to true if first_name doesn't match
        } else {
          setTeamWorkloadData(false); // Set teamWorkLoad to false if first_name matches
        }
      } else {
        setTeamWorkloadData(true); // Default to false or handle accordingly
      }
    } else {
      if (data.length === 1) {
        if (data[0].firstName !== userA.first_name) {
          setTeamWorkLoadDataTwo(true); // Set teamWorkLoad to true if first_name doesn't match
        } else {
          setTeamWorkLoadDataTwo(false); // Set teamWorkLoad to false if first_name matches
        }
      } else {
        setTeamWorkLoadDataTwo(true); // Default to false or handle accordingly
      }
   
    }
 
 
  };
 
  useEffect(() => {
    // Retrieve the menu from localStorage
    const menu = JSON.parse(localStorage.getItem('menu'));
 
    // Check if any caption matches "audit" or "Reporting Module"
    if (menu) {
      setShowAudit(menu.some(item => item.caption === "Audit"));
      setShowReportingModule(menu.some(item => item.caption === "Reporting Module"));
    }
  }, []);
 
  const icons = {
    done: done,
    updated: updated,
    due: due,
    pending: defaulted,
  };
 
  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {
        fromDate:fromDate,
        toDate: toDate,
        financialYearId:financialYearId
      },
      "GET"
    );
    if (isSuccess) {
      setLastWeekAcitivities(data?.data);
    }
  };
 
  const dashboardMenu = JSON.parse(localStorage.getItem("menu"));
  const dashboardObject = dashboardMenu?.find(
    (item) => item.caption === "Dashboard"
  ).permissions;
 
  useEffect(() => {
    setPermissionList(dashboardObject);
    lastWeekActivity();
  }, []);

  const teamWorkloadProgess = async () => {
    const frameworkIds = framework.map(item => item.value);
    const locationIdsIds = locationOption && locationOption.map(item => item.id);
    const periods = Object.values(timePeriods);
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}teamWorkloadProgess`,
      {},
      { fromDate: fromDate, toDate: toDate, financialYearId, frameworkIds,locationIdsIds,periods},
      "GET"
    );
    if (isSuccess) {
      const foundObject = data?.data?.teamWorkloadResults && data?.data?.teamWorkloadResults.length && data?.data?.teamWorkloadResults.find(item => item.userId === Number(userA.id));
      setTeamWorkloadDatas(foundObject);
    }
  };

  useEffect(() => {
    if (framework && framework.length && financialYearId && locationOption && locationOption.length ) {
      teamWorkloadProgess();
    }
  }, [framework, locationOption, timePeriods, financialYearId]);


  return (
    <div className="progress-container">
      <div className="topcompo">
        {lastWeekAcitivities && (
          <TopComponent
            lastWeekAcitivities={lastWeekAcitivities}
            icons={icons}
          />
        )}
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "80vh", marginBottom: "1%" }}
      >
        {userA && userA.head_office && <div className="d-flex" style={{ flexDirection:"column",justifyContent:"space-between",height: "100%", width:"50%" }}>
          <div style={{height:"55%", width:"100%"}}>
          <FrameworkComponent
            fromDate={fromDate}
            framework={framework}
            toDate={toDate}
            financialYearId={financialYearId}
            timePeriods={timePeriods}
            locationOption={locationOption}
          />
          </div>
 
          {permissionList?.some(
            (permission) =>
              permission.permissionCode === "RECENT_ACTIVITY" &&
              permission.checked
          ) && (
            <div className="nothing" style={{ height: "40%", width:"100%" }}>
               <RecentActivity
              fromDate={fromDate}
              toDate={toDate}
              financialYearId={financialYearId}
            />
 
            </div>
           
          )}
       
        </div>}
       
        <div className="secondhalfprogress" style={{ height: "100%", width:"50%" }}>
          {userA && userA.head_office && <div style={{ height: "10%", marginBottom: "1%" }}>
       
        
            <AnsweredQuestions
              fromDate={fromDate}
              toDate={toDate}
              financialYearId={financialYearId}
            />
          </div>}
          {userA && userA.head_office && <div style={{ height: "10%", marginBottom: "1%" }}>
            <UnAnsweredQuestions
              fromDate={fromDate}
              toDate={toDate}
              financialYearId={financialYearId}
            />
          </div>}
          {
            userA && userA.head_office && <div style={{ height: "78%" }}>
            <SectorProgress
              fromDate={fromDate}
              toDate={toDate}
              financialYearId={financialYearId}
            />
          </div>
 
          }
         
        </div>
      </div>
 
      {/* <div className="d-flex flex-row flex-space-between secondarycont">
        <div className="firsthalfprogress">
          <SummeryGraph fromDate={fromDate} toDate={toDate} financialYearId={financialYearId}/>
        </div>
        <div className="secondhalfprogress">
          <UnassignedPie fromDate={fromDate} toDate={toDate} financialYearId={financialYearId}/>
 
        </div>
      </div> */}
      <div
        className="d-flex flex-row flex-space-between secondarycont"
        style={{ height: "60vh" }}
      >
       { teamWorkloadDatas && teamWorkloadDatas?.totalAssignedQuestionForAudit !== 0 && <div
          className="nothing"
          style={{ height: "100%", width: "50%" }}
        >
          <RadialBarChartWithRadioButtons graphData = {teamWorkloadDatas}/>
        </div>}
       { teamWorkloadDatas && teamWorkloadDatas?.totalAssignedQuestionForAnswered !== 0 && <div
          className="nothing"
          style={{ height: "100%", width: "50%", marginLeft: showAudit ? "2%" : "0%"}}
        >
          <QuestionStatusBarChart graphData = {teamWorkloadDatas}/>
        </div>}
      </div>
      <div
        className="d-flex flex-row flex-space-between secondarycont"
        style={{ height: "60vh" }}
      >
        {/* {locationOption &&
        locationOption.length &&
        locationOption.length >= 2 ? (
          <div className="firsthalfprogresss" style={{ height: "100%" }}>
            <FocusAreaComponent
              fromDate={fromDate}
              toDate={toDate}
              financialYearId={financialYearId}
            />
          </div>
        ) : (
          <></>
        )} */}
       { teamWorkloadData && <div
          className="nothing"
          style={{ height: "100%", width: "50%" }}
        >
          <TeamWorkLoad
            fromDate={fromDate}
            toDate={toDate}
            financialYearId={financialYearId}
            handleTeamWorkloadData={handleTeamWorkloadData}
            user="audit"
            framework={framework}
            timePeriods={timePeriods}
            locationOption={locationOption}
          />
        </div>}
       { teamWorkloadData && <div
          className="nothing"
          style={{ height: "100%", width: "50%", marginLeft:"2%" }}
        >
          <TeamWorkLoad
            fromDate={fromDate}
            toDate={toDate}
            financialYearId={financialYearId}
            handleTeamWorkloadData={handleTeamWorkloadData}
            user="user"
            framework={framework}
            timePeriods={timePeriods}
            locationOption={locationOption}
          />
        </div>}
      </div>
    </div>
  );
};
 
export default Progress;